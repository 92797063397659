import { createActionGroup, emptyProps } from '@ngrx/store';

export const initEffectActions = createActionGroup({
  source: 'initialize/initEffect',
  events: {
    startSelectingSlots: emptyProps(),
    stopSelectingSlots: emptyProps(),

    startSelectedSlotsManagement: emptyProps(),
    stopSelectedSlotsManagement: emptyProps(),

    startSearchQueryManagement: emptyProps(),
    stopSearchQueryManagement: emptyProps(),

    startUserSearchFavoritesAndRecentManagement: emptyProps(),
    stopUserSearchFavoritesAndRecentManagement: emptyProps(),

    startLoadingSlotsBasedOnSystemSearch: emptyProps(),
    stopLoadingSlotsBasedOnSystemSearch: emptyProps(),

    startScheduleManagement: emptyProps(),
    stopScheduleManagement: emptyProps(),

    startSearchQueryLocationStatistics: emptyProps(),
    stopSearchQueryLocationStatistics: emptyProps(),

    startCandidateAssign: emptyProps(),
    stopCandidateAssign: emptyProps(),

    startCreateSearchQueryForLocationSchedule: emptyProps(),
    stopCreateSearchQueryForLocationSchedule: emptyProps(),

    startCreateSearchQueryForSearchView: emptyProps(),
    stopCreateSearchQueryForSearchView: emptyProps(),

    startProductScheduleManagement: emptyProps(),
    stopProductScheduleManagement: emptyProps(),

    startCandidateDetailsManagement: emptyProps(),
    stopCandidateDetailsManagement: emptyProps(),

    /** Approaches - start **/
    startManageApproachLists: emptyProps(),
    stopManageApproachLists: emptyProps(),

    startFetchingBestMatchingSlotsForCandidate: emptyProps(),
    stopFetchingBestMatchingSlotsForCandidate: emptyProps(),

    startCreatingApproachToSendToCandidate: emptyProps(),
    stopCreatingApproachToSendToCandidate: emptyProps(),

    startHandleCandidateReactingToApproach: emptyProps(),
    stopHandleCandidateReactingToApproach: emptyProps(),

    startRevisitingApproachesManagement: emptyProps(),
    stopRevisitingApproachesManagement: emptyProps(),
    /** Approaches - end **/

    startShowAlternativeSlots: emptyProps(),
    stopShowAlternativeSlots: emptyProps(),

    startShowInlineAssignButtons: emptyProps(),
    stopShowInlineAssignButtons: emptyProps(),

    startShowAssignmentAsNotAvailableAssigning: emptyProps(),
    stopShowAssignmentAsNotAvailableAssigning: emptyProps(),
  },
});
