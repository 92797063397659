import { UsersSelectors } from './users.selectors';

export class UsersViewSelectors {
  public static readonly viewHQHoursApprovalPage = UsersSelectors.isSchedulingTeam;
  public static readonly viewLocationHoursApprovalPage = UsersSelectors.isLocationManager;

  public static readonly viewHQPayoutPage = UsersSelectors.isAdmin;
  public static readonly viewLocationPayoutPage = UsersSelectors.isLocationManager;

  public static readonly viewSearchPage = UsersSelectors.isLocationManager;
  public static readonly viewLocationAgendaPage = UsersSelectors.isLocationManager;
  public static readonly viewCandidatesPage = UsersSelectors.isSchedulingTeam;
}
