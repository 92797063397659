import { Params } from '@angular/router';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EffectToInit } from '../enums/effect-to-init.enum';
import { RouterState } from './initialize.reducer';

export const initializeActions = createActionGroup({
  source: 'initialize',
  events: {
    triggerInit: props<{ processes: EffectToInit[] }>(),
    void: emptyProps(),
    addRouterHistory: props<{
      history: RouterState;
    }>(),
    changeCurrentRouteQueryParams: props<{
      key: string;
      newValue: string[] | string | undefined | null;
    }>(),
    changeCurrentRouteQueryParamsSuccess: props<{ params: Params }>(),
  },
});
